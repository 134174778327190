<template>
    <router-view></router-view>
</template>

<script>
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex'
export default {
    name: "user",
    data () {
        return {

        }
    },
    computed: {
        ...mapGetters(['isLogin', 'userInfo'])
    },
    onLoad () {
        console.log('data', this.isLogin)
    },
    methods: {

    }
}
</script>

<style lang="less" scoped></style>